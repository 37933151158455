@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200;300;400;500;600;700;800&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Rubik';
}

body.dark {
    background-color: #041a37;
    color: white;

    table {
        thead {
            background-color: #054EAD;
        }
    }

    input, select {
        color: black;
    }
}

/* Global Scrollbar */

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #054EAD #EBF1F9;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

*::-webkit-scrollbar-track {
    background: rgb(255, 243, 232);
    border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(126, 126, 151);
    border-radius: 20px;
    border-radius: 5px;
}